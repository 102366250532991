import * as React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  update_block: {
    padding: '10px'
  },
  header: {
    fontSize: 'large',
    fontWeight: 'bold'
  },
  subheader: {
    fontSize: 'large'
  },
  date: {
    fontSize: 'x-small'
  }
});

export interface Release0202Props {
  className?: string;
}

const Release0311 = ({ className }: Release0202Props) => {
  const styles = useStyles();

  return (
    <div>
      <div className={styles.update_block}>
        <hr />
        <div>
          <div className={styles.subheader}>
            3.1.1 <span className={styles.date}>(2025-02-09)</span>
          </div>
          <br />
          Changes:
          <br />
          <ul>
            <li>
              <i>Fixed bad sentence splitting with using multiple punctuation marks.</i>
            </li>
          </ul>
          <br />
          ~Auri &nbsp;
          <a href='mailto:auriana@ffxiv-toolkit.com'>
            &lt;auriana@ffxiv-toolkit.com&gt;
          </a>
        </div>
      </div>
    </div>
  );
};
export default Release0311;
